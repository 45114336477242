<template>
    <v-card class="ma-4">
      <v-toolbar :color="selectedItems.length ? 'grey darken-4' : '#335D6E'" dark
                 elevation="1">


        <v-toolbar-title> {{ selectedItems.length ? `Selected (${selectedItems.length})  rows` : title }}</v-toolbar-title>
            <v-spacer></v-spacer>

            <v-text-field
                    v-if="!selectedItems.length"
                    v-model="search"
                    append-icon="mdi-magnify"
                    clearable
                    filled
                    hide-details
                    label="Search about something"
                    single-line
            ></v-text-field>


        <v-btn
            v-if="selectedItems.length"
            color="grey darken-2"
            dark
            @click.stop="restoreItems"
        >Restore
        </v-btn>

            <v-dialog v-if="!selectedItems.length" v-model="dialogAddItem" max-width="750px">

                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                      class="mx-11"
                      color="#082d3c"
                      dark
                      v-bind="attrs"
                      v-on="on"
                  >New
                  </v-btn>
                </template>

              <v-card v-if="dialogAddItem">
                <v-toolbar
                    color="blue-grey"
                    dark
                >
                  <v-btn
                      dark
                      icon
                      @click="dialogAddItem = false"
                  >
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                  <v-toolbar-title>{{ formTitle }}</v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-toolbar-items>
                    <v-btn
                        :disabled="(!valid || buttonLoading)"
                        :loading="buttonLoading"
                        dark
                        text
                        @click="validate"
                    >Save
                    </v-btn>
                  </v-toolbar-items>
                </v-toolbar>
                <v-alert
                    v-if="Object.keys(errors).length>0"
                    dense
                    text
                    type="error"
                >
                  <h4 class="subtitle">Correct the following errors:</h4>
                  <ul>
                    <li
                        v-for="(error, index) in errors"
                        :key="index"
                    >{{ error.message || error }}
                    </li>
                  </ul>
                </v-alert>


                    <v-card-text>
                        <v-container>

                            <v-form
                                    ref="form"
                                    v-model="valid"
                                    :disabled="buttonLoading"
                                    lazy-validation
                            >
                                <v-row>
                                    <v-col cols="12">
                                        <v-text-field v-model="key" :counter="150" :disabled="isFormTitleEdit" :readonly="isFormTitleEdit"
                                                      :rules="keyRules"
                                                      clearable
                                                      label="Keyword key field" required>

                                        </v-text-field>
                                    </v-col>

                                    <v-col cols="12">
                                        <v-textarea v-model="value" :counter="1000" :rules="valueRules" auto-grow
                                                    clearable
                                                    label="Describe value field" name="describe"
                                        ></v-textarea>
                                    </v-col>


                                    <v-col cols="12">
                                        <v-checkbox
                                                v-model="isActive"
                                                :ripple=false
                                                label="Active this option"
                                        ></v-checkbox>
                                    </v-col>

                                </v-row>
                            </v-form>
                        </v-container>
                    </v-card-text>

                </v-card>
            </v-dialog>

        <v-btn
            v-else
            class="mx-11"
            color="grey darken-2"
            dark
            @click.stop="showDialogDelete"
        >Delete
        </v-btn>
        <v-btn
            v-if="selectedItems.length"
            icon
            @click="selectedItems = []"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-menu
            v-else
            :close-on-content-click="false"
            :nudge-width="250"
            offset-x
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
                icon
                v-bind="attrs"
                v-on="on"
            >
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>

          <v-card>
            <v-list>
              <v-list-item>
                <v-list-item-action>
                  <v-switch
                      v-model="isShowDeleted"
                      color="indigo"
                  ></v-switch>
                </v-list-item-action>
                <v-list-item-title class="ml-4">Show deleted data</v-list-item-title>
              </v-list-item>

              <v-list-item>
                <v-list-item-action>
                  <v-switch
                      v-model="isForceDeleted"
                      color="indigo"
                  ></v-switch>
                </v-list-item-action>
                <v-list-item-title class="ml-4">Delete data forever</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-card>
        </v-menu>
      </v-toolbar>

      <v-card-text>
        <v-dialog
            v-model="dialogDelete"
            max-width="425"
            persistent
        >
          <v-card>
            <v-card-title>
              Delete group of data ({{ selectedItems.length ? selectedItems.length : 1 }}) rows
            </v-card-title>

            <v-card-text class=" ">
              Are you sure for continue deleting these data ?
            </v-card-text>

            <v-card-actions>
              <v-checkbox
                  v-model="isForceDeletedRow"
                  :disabled=disableChangeDelete
                  :ripple=false
                  class="ma-2 red--text "
                  color="red"
                  hide-details
              >
                <template v-slot:label>
                  <div class="red--text body-2">
                    Delete data forever
                  </div>
                </template>
              </v-checkbox>

              <v-spacer></v-spacer>

              <v-btn
                  :disabled="buttonLoading"
                  class="body-2"
                  color="primary"
                  text
                  @click="hideDialogDelete()"
              >
                Reject
              </v-btn>

              <v-btn
                  :disabled="buttonLoading"
                  class="body-2"
                  color="red darken-2"
                  text
                  @click="destroyItems"
              >
                Process
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog
            v-if="!dialogDelete && !dialogAddItem"
            v-model="buttonLoading"
            hide-overlay
            persistent
            width="300"
        >
          <v-card
              color="indigo"
              dark
          >
            <v-card-text>
              <div class="py-4 body-1 white--text text-center">Waiting for process request... please wait</div>

              <v-progress-linear
                  class="my-2"
                  color="white"
                  indeterminate
              ></v-progress-linear>
            </v-card-text>
          </v-card>
        </v-dialog>

        <v-data-table
            v-model="selectedItems"
            :footer-props="{'items-per-page-options': [10, 20, 40, 80, 100]}"
            :headers="headers"
            :item-class="(item)=>item.deletedAt?'deletedRow':null"
            :items="desserts"
            :items-per-page="10"
            :loading="loading"
            :options.sync="options"
            :search="search"
            checkbox-color="'blue'"
            item-key='id'
            show-select
        >


          <template v-slot:header.data-table-select="{props,on}">
            <v-simple-checkbox :ripple=false color="primary" v-bind="props" v-on="on"></v-simple-checkbox>
          </template>
          <template v-slot:item.data-table-select="{isSelected,select}">
            <v-simple-checkbox :ripple=false :value="isSelected" color="primary" @input="select($event)"></v-simple-checkbox>
          </template>
          <template v-slot:item.id="{ item }">
            <v-chip>{{ item.id }}</v-chip>
          </template>
          <template v-slot:item.deletedAt="{ item }">
            <v-icon
                v-if="item.deletedAt"
                class="mr-3"
                color="primary"
                small
                @click="restoreItems(item)"
            >mdi-restore
            </v-icon>


            <v-chip
                color="indigo"
                label
                outlined
                small
                @click="editItem(item)"
            >
              <v-icon small>
                mdi-pencil
              </v-icon>
            </v-chip>


            <v-chip
                :color="item.deletedAt ?'orange':'red accent-4'"
                class="ml-3"
                label
                outlined
                small
                @click="showDialogDelete(item)"
            >
              <v-icon small>
                {{ item.deletedAt ? 'mdi-delete-clock' : ' mdi-delete' }}
              </v-icon>
            </v-chip>

          </template>


          <template v-slot:item.isActive="{ item }">
            <v-icon :color="(item.isActive)?'green':'red'">
              mdi-eye
            </v-icon>
          </template>


          <template v-slot:item.isForAddon="{ item }">
            <v-chip color="orange" dark>{{ item.isForAddon ? 'Addon' : 'Boiler' }}</v-chip>
          </template>


          <template v-slot:item.name="{ item }">
            <v-chip>{{ item.name }}</v-chip>
          </template>


        </v-data-table>
      </v-card-text>
    </v-card>
</template>

<script>
  import networks from '../../services/networks';
import {urlCreateOptions, urlOptions, urlUpdateOptions}   from "@/config";

export default {
    data: () => ({
        search: null,
        dialogDelete: false,
        disableChangeDelete: false,
        deleteItemIDs: [],
        selectedItems: [],
        valid: true,
        key: null,
        keyRules: [
            v => !!v || 'Keyword key is required',
            v => (v && v.length <= 150) || 'the key must be at least 150 characters',
        ],
        value: null,
        valueRules: [
            v => (!v || v.length <= 1000) || 'the value must be at least 1000 characters',
        ],
        isActive: true,

        errors: [],
        buttonLoading: false,
        dialogAddItem: false,

        title: "Options for management",

        options: null,
        desserts: [],
        loading: true,
        headers: [
            {text: 'Keyword', value: 'key',},
            {text: 'Status', value: 'isActive', align: 'center'},
          {text: 'Actions', value: 'deletedAt', sortable: false, align: 'right'},
        ],
        isFormTitleEdit: false,


    }),

    created() {
        this.getOptionsFromApi();
    },


    computed: {
        formTitle() {
            return this.isFormTitleEdit ? 'Edit options' : 'Add new options'
        },

        isShowDeleted: {
            get() {
                return this.$store.getters.isShowDeleted;
            },
            set(val) {
                this.$store.state.isShowDeleted = val;
                this.getOptionsFromApi();
                return val;
            }


        },
        isForceDeleted: {
            get() {
                return this.$store.getters.isForceDeleted;
            },
            set(val) {
                this.isForceDeletedRow = val;
                this.$store.state.isForceDeleted = val;
                return val;
            }
        },
        isForceDeletedRow: {
            get() {
                return this.$store.getters.isForceDeleted;
            },
            set(val) {
                this.$store.state.isForceDeleted = val;
                return val;
            }
        },


    },

    watch: {
        dialogAddItem() {
            if (!this.dialogAddItem) {
                this.isFormTitleEdit = false;
                this.clear();
            }

        },
    },

    methods: {
        getOptionsFromApi() {
            this.loading = true;
            networks.fetchFromWeb(urlOptions + this.isShowDeleted)
                .then(response => {
                    this.desserts = response.data;
                    this.loading = false;
                })
                .catch(error => {
                    alert(error);
                });
        },

        validate() {
            if (this.$refs.form.validate()) {
                this.buttonLoading = true;
                this.errors = [];
                this.saveItem();
            }
        },

        saveItem() {

            let data = {
                key: this.key,
                value: this.value,
                isActive: this.isActive,
            };


            if (this.isFormTitleEdit)
                networks.fetchFromWeb(urlUpdateOptions + this.key, 2, data)
                    .then(response => {

                        if (response.status == 200) {
                            this.clear();
                            this.dialogAddItem = false;
                          alert("the data has been edited successfully");
                            this.getOptionsFromApi();
                        }


                    })
                    .catch(error => {
                        this.errors = error.response.data.errors || error.response.data.error;
                        this.buttonLoading = false;
                    });
            else {
                networks.fetchFromWeb(urlCreateOptions, 1, data)
                    .then(response => {

                        if (response.status == 200) {
                            this.clear();
                            this.dialogAddItem = false;
                          alert("the data has been added successfully");
                            this.getOptionsFromApi();
                        }


                    })
                    .catch(error => {
                        this.errors = error.response.data.errors || error.response.data.error;
                        this.buttonLoading = false;

                    });
            }

        },

        clear() {
            if (this.$refs.form)
                this.$refs.form.resetValidation();

            this.key = null;
            this.value = null;
            this.isActive = true;
            this.valid = true;
            this.errors = [];
            this.buttonLoading = false;
        },


        editItem(item) {

            this.clear();
            this.isFormTitleEdit = true;
            this.key = item.key;
            this.value = item.value;
            this.isActive = item.isActive;
            this.dialogAddItem = true;


        },

        showDialogDelete(item) {
            if (this.selectedItems.length < 1) {
                if (!item.deletedAt || (item.deletedAt && this.isForceDeleted)) {
                    this.disableChangeDelete = item.deletedAt;
                    this.dialogDelete = true;
                    this.deleteItemIDs.push(item.key); // replace for with item.id for other tables
                }
            } else {
                this.dialogDelete = true;
            }

        },
        hideDialogDelete() {
            this.dialogDelete = false;
            this.disableChangeDelete = false;
            this.deleteItemIDs = [];
            this.selectedItems = [];
        },
        destroyItems() {

            this.buttonLoading = true;
            if (this.selectedItems.length > 0) {
                let currentSelected = [];

                if (this.isForceDeletedRow)
                    currentSelected = this.selectedItems;
                else
                    currentSelected = this.selectedItems.filter((e) => e.deletedAt === false);


                currentSelected.map((e) => this.deleteItemIDs.push(e.key));
            }


            if (this.deleteItemIDs.length < 1) {
                this.hideDialogDelete();
                this.buttonLoading = false
            } else {
                networks.fetchFromWeb(urlOptions + this.deleteItemIDs + "/" + this.isForceDeletedRow, 3)
                    .then(response => {

                        if (response.status == 200) {
                            this.hideDialogDelete();
                          alert("Data has been deleted");
                            this.getOptionsFromApi();
                        }

                    })
                    .catch(() => {
                      alert("Failed for process delete data");
                        this.hideDialogDelete();
                    }).finally(() => this.buttonLoading = false);
            }

        },

        restoreItems(item) {
            this.buttonLoading = true;
            if (this.selectedItems.length > 0) {
                let currentSelected = [];
                currentSelected = this.selectedItems.filter((e) => e.deletedAt === true);
                currentSelected.map((e) => this.deleteItemIDs.push(e.key));

            } else {
                this.deleteItemIDs.push(item.key); // replace for with item.id for other tables
            }

            if (this.deleteItemIDs.length < 1) {
                this.hideDialogDelete();
                this.buttonLoading = false;
            } else {

                networks.fetchFromWeb(urlOptions + this.deleteItemIDs + "/" + this.isForceDeletedRow + "/true", 3)
                    .then(response => {

                        if (response.status == 200) {
                            this.hideDialogDelete();

                            this.getOptionsFromApi();
                        }

                    })
                    .catch(() => {
                      alert("Failed for process delete data");
                        this.hideDialogDelete();
                    }).finally(() => this.buttonLoading = false);

            }

        }
    },
}
</script>



